jQuery(document).ready(function($) {
	// Cache selectors
	var lastId;
	var topMenu = $(".nav");
	var topMenuHeight = topMenu.outerHeight();
	var menuItems = $('.nav__item');

	// Anchors corresponding to menu items
	var scrollItems = menuItems.map(function(){
		var item = $(this).attr("href");

		if (item.length) {
			return item;
		}
	});


	menuItems.click(function(e){
		var href = $(this).attr("href");
		var offsetTop = href === "#" ? 0 : $(href).offset().top;

		$(this).addClass('is-active').siblings().removeClass('is-active')

		$('html, body').stop().animate({
			scrollTop: offsetTop
		}, 800);

		e.preventDefault();
	});


	// MAP
	var mapDesc = $('#map-desc');
	var mapSwitch = $('.map-switch__item');
	var mapMarker = $('.map-marker');

	if ($(window).width() > 780) {
		mapSwitch.on('click', function(event) {
			event.preventDefault();
			$(this).addClass('is-active').siblings().removeClass('is-active');

			if ($('.show-2020').hasClass('is-active')) {
				$('.is-hidden').show()
			}
			else {
				$('.is-hidden').hide()
			}
		});

		mapMarker.on('click', function(event) {
			event.preventDefault();

			var name = $(this).data('name')
			var location = $(this).data('location')
			var city = $(this).data('city')
			var contact = $(this).data('contact')
			var phone = $(this).data('phone')
			var mail = $(this).data('mail')
			var website = $(this).data('website')

			mapDesc.html(`<p><strong>${name}</strong></p><p>${location}</p><p>${city}</p><p>${phone}</p><p><a class="btn" href="${website}" target="_blank">Voir le site</a></p>`)
		});


		// mapMarker.each(function(index, el) {
		// 	var name = $(this).data('name')
		// 	var location = $(this).data('location')
		// 	var city = $(this).data('city')
		// 	var contact = $(this).data('contact')
		// 	var phone = $(this).data('phone')
		// 	var mail = $(this).data('mail')
		// 	var website = $(this).data('website')

		// 	mapDesc.append(`<div class="etablissement"><p><strong>${name}</strong></p><p>${location}</p><p>${city}</p><p class="contact">Contact :</p><p>${contact}</p><p>${phone}</p><p><a href="mailto:${mail}">${mail}</a></p><p><a class="btn" href="${website}" target="_blank">Voir le site</a></p></div>`)
		// });
	}
	else {
		mapSwitch.on('click', function(event) {
			event.preventDefault();
			$(this).addClass('is-active').siblings().removeClass('is-active');

			if ($(this).hasClass('show-2020')) {
				$('.etablissement').hide();
				$('.next-year').show();
			}
			else {
				$('.etablissement').show();
				$('.next-year').hide();
			}
		});
	}
});